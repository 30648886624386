<template>
  <div>
    <div>
      <div class="wrapper-table">
        <table class="table-data" border>
          <thead>
          <tr>
            <td class="table-index">STT</td>
            <td style="text-align: center">Nội dung</td>
            <td class="table-action">Thiết lập</td>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="table-index">1</td>
            <td>Có cần duyệt album ảnh hay không?</td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.album">
                <el-radio :label="false">Có</el-radio>
                <el-radio :label="true">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">2</td>
            <td>Có cần duyệt đánh giá nhận xét hay không?</td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.evaluate">
                <el-radio :label="false">Có</el-radio>
                <el-radio :label="true">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">3</td>
            <td>Có cho phép sửa đánh giá khi đã duyệt hay không?</td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.editAproved">
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">4</td>
            <td>Có hiển thị chức năng đánh giá ngày hay không?</td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.evaluateDate">
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">5</td>
            <td>Có hiển thị chức năng đánh giá tuần hay không?</td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.evaluateWeek">
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">6</td>
            <td>Có hiển thị chức năng đánh giá tháng hay không?</td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.evaluateMonth">
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">7</td>
            <td>Có hiển thị chức năng đánh giá định kỳ hay không?</td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.evaluatePeriod">
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">8</td>
            <td>Có gửi thông báo điểm danh đến hay không?</td>
            <td class="table-action">
              <el-radio-group
                  v-model="schoolConfigInSysResponse.approvedAttendanceArrive"
              >
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">9</td>
            <td>Giới hạn thời gian gửi điểm danh đến trước mấy giờ?</td>
            <td class="table-action">
              <el-time-picker
                  class="input-data"
                  size="mini"
                  :clearable="false"
                  type="time"
                  value-format="HH:mm"
                  format="HH:mm"
                  v-model="schoolConfigInSysResponse.timeAttendanceArrive"
                  :picker-options="{
                    selectableRange: '06:00:00 - 21:00:00',
                  }"
                  placeholder="Chọn"
              ></el-time-picker>
            </td>
          </tr>

          <tr>
            <td class="table-index">10</td>
            <td>Có gửi thông báo điểm danh về hay không?</td>
            <td class="table-action">
              <el-radio-group
                  v-model="schoolConfigInSysResponse.approvedAttendanceLeave"
              >
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">11</td>
            <td>Giới hạn thời gian gửi điểm danh về trước mấy giờ?</td>
            <td class="table-action">
              <el-time-picker
                  class="input-data"
                  size="mini"
                  :clearable="false"
                  type="time"
                  value-format="HH:mm"
                  format="HH:mm"
                  v-model="schoolConfigInSysResponse.timeAttendanceLeave"
                  :picker-options="{
                    selectableRange: '06:00:00 - 21:00:00',
                  }"
                  placeholder="Chọn"
              ></el-time-picker>
            </td>
          </tr>

          <tr>
            <td class="table-index">12</td>
            <td>Cho phép điểm danh lại với học sinh mấy ngày trước?</td>
            <td class="table-action">
              <el-input
                  class="input-data"
                  size="mini"
                  v-model="schoolConfigInSysResponse.againAttendance"
                  placeholder="Nhập số ngày"
              ></el-input>
            </td>
          </tr>

          <tr>
            <td class="table-index">13</td>
            <td>
              Gửi đơn xin nghỉ phải trước ngày đầu tiên muốn nghỉ số ngày là?
            </td>
            <td class="table-action">
              <el-input
                  class="input-data"
                  size="mini"
                  v-model="schoolConfigInSysResponse.dateAbsent"
                  placeholder="Nhập số ngày"
              ></el-input>
            </td>
          </tr>

          <tr>
            <td class="table-index">14</td>
            <td>Thời gian xin nghỉ phải trước mấy giờ?</td>
            <td class="table-action">
              <el-time-picker
                  class="input-data"
                  size="mini"
                  :clearable="false"
                  type="time"
                  value-format="HH:mm"
                  format="HH:mm"
                  v-model="schoolConfigInSysResponse.timeAbsent"
                  :picker-options="{
                    selectableRange: '06:00:00 - 21:00:00',
                  }"
                  placeholder="Chọn"
              ></el-time-picker>
            </td>
          </tr>

          <tr>
            <td class="table-index">15</td>
            <td>Mặc định có học sáng thứ 7 hàng tuần không?</td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.morningSaturday">
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">16</td>
            <td>Mặc định có học chiều thứ 7 hàng tuần không?</td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.afternoonSaturday">
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">17</td>
            <td>Mặc định có học tối thứ 7 hàng tuần không?</td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.eveningSaturday">
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">18</td>
            <td>Mặc định có học chủ nhật hàng tuần không?</td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.sunday">
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">19</td>
            <td>Hiển thị điểm danh đi học buổi sáng không?</td>
            <td class="table-action">
              <el-radio-group
                  v-model="schoolConfigInSysResponse.morningAttendanceArrive"
              >
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">20</td>
            <td>Hiển thị điểm danh đi học buổi chiều không?</td>
            <td class="table-action">
              <el-radio-group
                  v-model="schoolConfigInSysResponse.afternoonAttendanceArrive"
              >
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">21</td>
            <td>Hiển thị điểm danh đi học buổi tối không?</td>
            <td class="table-action">
              <el-radio-group
                  v-model="schoolConfigInSysResponse.eveningAttendanceArrive"
              >
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">22</td>
            <td>Hiển thị điểm danh ăn bữa sáng không?</td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.morningEat">
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">23</td>
            <td>Hiển thị điểm danh ăn bữa phụ sáng không?</td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.secondMorningEat">
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">24</td>
            <td>Hiển thị điểm danh ăn bữa trưa không?</td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.lunchEat">
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">25</td>
            <td>Hiển thị điểm danh ăn bữa chiều không?</td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.afternoonEat">
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">26</td>
            <td>Hiển thị điểm danh ăn bữa phụ chiều không?</td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.secondAfternoonEat">
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">27</td>
            <td>Hiển thị điểm danh ăn bữa tối không?</td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.eveningEat">
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">28</td>
            <td>Có cần duyệt các thông báo gửi đi hay không?</td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.appSendApproved">
                <el-radio :label="false">Có</el-radio>
                <el-radio :label="true">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">29</td>
            <td>Có cần duyệt nội dung thời khóa biểu hay không?</td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.approvedSchedule">
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">30</td>
            <td>Có cần duyệt nội dung thực đơn hay không?</td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.approvedMenu">
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">31</td>
            <td>
              Cho phép hiển thị số điện thoại của Phụ huynh với App Giáo viên?
            </td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.parentPhone">
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">32</td>
            <td>
              Cho phép Giáo viên xem thông tin chi tiết của Phụ huynh hay
              không?
            </td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.parentInfo">
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">33</td>
            <td>
              Cho phép hiển thị số điện thoại của Giáo viên với App Phụ huynh?
            </td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.employeePhone">
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">34</td>
            <td>
              Cho phép Phụ huynh xem thông tin chi tiết của Giáo viên không?
            </td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.employeeInfo">
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">35</td>
            <td>
              Cho phép Phụ huynh xem lại lịch sử học của con khi đã chuyển
              trường hay không?
            </td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.historyViewParent">
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">36</td>
            <td>
              Cho phép Nhân viên xem lại lịch sử làm việc khi đã nghỉ làm ở
              trường hày không?
            </td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.historyViewTeacher">
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">37</td>
            <td>
              Giáo viên có được xem chi tiết các khoản thu của học sinh lớp
              mình hay không?
            </td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.feesInfo">
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">38</td>
            <td>
              Giáo viên có được xem số tiền học phí của học sinh hay không?
            </td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.feesNumber">
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">39</td>
            <td>Thiết lập giờ đến quy định của Nhà trường với học sinh?</td>
            <td class="table-action">
              <el-time-picker
                  class="input-data"
                  size="mini"
                  :clearable="false"
                  type="time"
                  value-format="HH:mm"
                  format="HH:mm"
                  v-model="schoolConfigInSysResponse.timeArriveKid"
                  :picker-options="{
                    selectableRange: '06:00:00 - 21:00:00',
                  }"
                  placeholder="Chọn"
              ></el-time-picker>
            </td>
          </tr>

          <tr>
            <td class="table-index">40</td>
            <td>Thiết lập giờ về quy định của Nhà trường với học sinh?</td>
            <td class="table-action">
              <el-time-picker
                  class="input-data"
                  size="mini"
                  :clearable="false"
                  type="time"
                  value-format="HH:mm"
                  format="HH:mm"
                  v-model="schoolConfigInSysResponse.timePickupKid"
                  :picker-options="{
                    selectableRange: '06:00:00 - 21:00:00',
                  }"
                  placeholder="Chọn"
              ></el-time-picker>
            </td>
          </tr>

          <tr>
            <td class="table-index">41</td>
            <td>Hiển thị thêm mẫu nhận xét của hệ thống?</td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.showEvaluateSys">
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">42</td>
            <td>Hiển thị thêm mẫu báo đến, báo về của hệ thống?</td>
            <td class="table-action">
              <el-radio-group
                  v-model="schoolConfigInSysResponse.showAttentendanceSys"
              >
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">43</td>
            <td>Hiển thị thêm mẫu lời chúc của hệ thống?</td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.showWishSys">
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">44</td>
            <td>Thiết lập giờ đi làm buổi sáng của Nhân viên Nhà trường?</td>
            <td class="table-action">
              <el-time-picker
                  class="input-data"
                  size="mini"
                  :clearable="false"
                  type="time"
                  value-format="HH:mm"
                  format="HH:mm"
                  v-model="schoolConfigInSysResponse.timeArriveEmployee"
                  :picker-options="{
                    selectableRange: '06:00:00 - 21:00:00',
                  }"
                  placeholder="Chọn"
              ></el-time-picker>
            </td>
          </tr>

          <tr>
            <td class="table-index">45</td>
            <td>Thiết lập giờ về của Nhân viên Nhà trường?</td>
            <td class="table-action">
              <el-time-picker
                  class="input-data"
                  size="mini"
                  :clearable="false"
                  type="time"
                  value-format="HH:mm"
                  format="HH:mm"
                  v-model="schoolConfigInSysResponse.timeLeaveEmployee"
                  :picker-options="{
                    selectableRange: '06:00:00 - 21:00:00',
                  }"
                  placeholder="Chọn"
              ></el-time-picker>
            </td>
          </tr>

          <tr>
            <td class="table-index">46</td>
            <td>Có tự động duyệt các phiếu thu không?</td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.receiptNote">
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">47</td>
            <td>Có tự động duyệt các phiếu chi không?</td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.paymentNote">
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">48</td>
            <td>
              Có sinh tự động hóa đơn công lương của Nhân viên cho tháng tiếp
              theo không (sinh tự động vào ngày đầu tháng)?
            </td>
            <td class="table-action">
              <el-radio-group
                  v-model="schoolConfigInSysResponse.autoBillSalaryEmployee"
              >
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">49</td>
            <td>
              Có sinh tự động các khoản công lương cho Nhân viên theo các
              khoản mặc định?
            </td>
            <td class="table-action">
              <el-radio-group
                  v-model="schoolConfigInSysResponse.autoSignSalaryEmployee"
              >
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">50</td>
            <td>
              Thiết lập ngày của tháng sẽ sinh tự động các khoản công lương
              cho Nhân sự?
            </td>
            <td class="table-action">
              <el-input-number
                  :disabled="!schoolConfigInSysResponse.autoSignSalaryEmployee"
                  size="mini"
                  :min="1"
                  :max="31"
                  v-model="schoolConfigInSysResponse.autoNextmonthSalaryDate"
              ></el-input-number>
            </td>
          </tr>

          <tr>
            <td class="table-index">51</td>
            <td>Có tự động duyệt các khoản lương hàng tháng không?</td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.autoApprovedSalary">
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">52</td>
            <td>
              Có tự động khóa các khoản lương khi đã thanh toán đủ không?
            </td>
            <td class="table-action">
              <el-radio-group
                  v-model="schoolConfigInSysResponse.autoLockSalarySuccess"
              >
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">53</td>
            <td>
              Có tự động hiển thị hóa đơn lương của cả tháng khi hóa đơn đã
              được sinh ra không?
            </td>
            <td class="table-action">
              <el-radio-group
                  v-model="schoolConfigInSysResponse.autoShowBillOfMonth"
              >
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">54</td>
            <td>
              Có sinh tự động hóa đơn học phí của học sinh cho tháng tiếp theo
              không (sinh tự động vào ngày đầu tháng)?
            </td>
            <td class="table-action">
              <el-radio-group
                  v-model="schoolConfigInSysResponse.autoFeesApprovedKids"
              >
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">55</td>
            <td>
              Có sinh tự động các khoản thu với học sinh theo các khoản thu đã
              đăng ký?
            </td>
            <td class="table-action">
              <el-radio-group
                  v-model="schoolConfigInSysResponse.autoGenerateFeesKids"
              >
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">56</td>
            <td>
              Thiết lập ngày của tháng sẽ sinh tự động các khoản thu cho học
              sinh?
            </td>
            <td class="table-action">
              <el-input-number
                  :disabled="!schoolConfigInSysResponse.autoGenerateFeesKids"
                  size="mini"
                  :min="1"
                  :max="31"
                  v-model="schoolConfigInSysResponse.autoNexMonthFeesDate"
              ></el-input-number>
            </td>
          </tr>

          <tr>
            <td class="table-index">57</td>
            <td>
              Có tự động duyệt các khoản thu của học sinh hàng tháng không?
            </td>
            <td class="table-action">
              <el-radio-group
                  v-model="schoolConfigInSysResponse.autoApprovedFeesMonthKids"
              >
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">58</td>
            <td>
              Có tự động khóa các khoản thu khi Phụ huynh đã thanh toán đủ
              không?
            </td>
            <td class="table-action">
              <el-radio-group
                  v-model="schoolConfigInSysResponse.autoLockFeesSuccessKids"
              >
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">59</td>
            <td>
              Có tự động hiển thị hóa đơn học phí của cả tháng khi hóa đơn đã
              được sinh ra không?
            </td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.autoShowFeesMonth">
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">60</td>
            <td>
              Có hiển thị thông tin các khoản thu dự kiến cho Phụ huynh học
              sinh không?
            </td>
            <td class="table-action">
              <el-radio-group
                  v-model="schoolConfigInSysResponse.autoShowFeesFutureKids"
              >
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td class="table-index">61</td>
            <td>Có cho phép Phụ huynh truy cập ví hay không?</td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.showWalletParent">
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">62</td>
            <td>
              Có hiển thị thông tin khoản thu dự kiến của học sinh cho Giáo
              viên không?
            </td>
            <td class="table-action">
              <el-radio-group
                  v-model="schoolConfigInSysResponse.autoShowFeesFutureKidsForTeacher"
              >
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">63</td>
            <td>Hiển thị nút xóa nhân sự, học sinh hay không?</td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.deleteStatus">
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="table-index">64</td>
            <td>Mã xử lý trùng tài khoản</td>
            <td class="table-action">
              <el-input
                  size="mini"
                  :maxlength="6"
                  v-model="schoolConfigInSysResponse.verifyCode"
              >
              </el-input>
            </td>
          </tr>

          <tr>
            <td class="table-index">65</td>
            <td>
              Có cho phép ra trường, nghỉ học khi chưa hoàn thành học phí
              không?
            </td>
            <td class="table-action">
              <el-radio-group
                  v-model="schoolConfigInSysResponse.checkCompleteFeesStatus"
              >
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td class="table-index">66</td>
            <td>Có gửi thông báo khi Nhà trường rút tiền từ ví không?</td>
            <td class="table-action">
              <el-radio-group
                  v-model="schoolConfigInSysResponse.notifyWalletOutStatus"
              >
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td class="table-index">67</td>
            <td>Hiện OneCam Plus trong danh sách Camera Nhà trường?</td>
            <td class="table-action">
              <el-radio-group v-model="schoolConfigInSysResponse.showOnecamPlus">
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td class="table-index">68</td>
            <td>Số lượng ảnh tối đa khi đăng Album</td>
            <td class="table-action">
              <el-input
                  size="mini"
                  v-model="schoolConfigInSysResponse.albumMaxNumber"
              >
              </el-input>
            </td>
          </tr>
          <!-- <tr>
            <td class="table-index">69</td>
            <td>Cho phép tài khoản mới đăng nhập đá tài khoản cũ nhất ra</td>
            <td class="table-action">
              <el-radio-group
                v-model="schoolConfigInSysResponse.approvedLogoutStatus"
              >
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr> -->
          <tr>
            <td class="table-index">70</td>
            <td>Áp dụng tự động điền điểm danh ăn sáng theo đi học?</td>
            <td class="table-action">
              <el-radio-group
                  v-model="schoolConfigInSysResponse.breakfastAuto"
              >
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td class="table-index">71</td>
            <td>Áp dụng tự động điền điểm danh ăn phụ sáng theo đi học?</td>
            <td class="table-action">
              <el-radio-group
                  v-model="schoolConfigInSysResponse.secondBreakfastAuto"
              >
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td class="table-index">72</td>
            <td>Áp dụng tự động điền điểm danh ăn trưa theo đi học?</td>
            <td class="table-action">
              <el-radio-group
                  v-model="schoolConfigInSysResponse.lunchAuto"
              >
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td class="table-index">73</td>
            <td>Áp dụng tự động điền điểm danh ăn chiều theo đi học?</td>
            <td class="table-action">
              <el-radio-group
                  v-model="schoolConfigInSysResponse.afternoonAuto"
              >
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td class="table-index">74</td>
            <td>Áp dụng tự động điền điểm danh ăn phụ chiều theo đi học?</td>
            <td class="table-action">
              <el-radio-group
                  v-model="schoolConfigInSysResponse.secondAfternoonAuto"
              >
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td class="table-index">75</td>
            <td>Áp dụng tự động điền điểm danh ăn tối theo đi học?</td>
            <td class="table-action">
              <el-radio-group
                  v-model="schoolConfigInSysResponse.dinnerAuto"
              >
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td class="table-index">76</td>
            <td>Có tự động tạo điểm danh khi học sinh xin nghỉ học quá thời hạn quy định hay không?</td>
            <td class="table-action">
              <el-radio-group
                  v-model="schoolConfigInSysResponse.attendanceCreateLetter"
              >
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td class="table-index">77</td>
            <td>Chọn trạng thái điểm danh mặc định khi xin nghỉ quá thời hạn?</td>
            <td class="table-action">
              <el-radio-group
                  v-model="schoolConfigInSysResponse.absentYesNoLetter"
              >
                <el-radio :label="true">Có phép</el-radio>
                <el-radio :label="false">Không phép</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr class="disable-row">
            <td class="table-index">78</td>
            <td>Thời gian gửi lời chúc mừng mặc định?</td>
            <td class="table-action">
                <span>{{
                    sysConfigResponse.timeSendCelebrate
                        | formatTime
                  }}</span>
            </td>
          </tr>

          <tr class="disable-row">
            <td class="table-index">79</td>
            <td>Chất lượng ảnh theo %?</td>
            <td class="table-action">
                <span>{{
                    sysConfigResponse.qualityPicture
                  }}</span>
            </td>
          </tr>

          <tr class="disable-row">
            <td class="table-index">80</td>
            <td>Chất lượng ảnh theo kích thước?</td>
            <td class="table-action">
                <span>{{
                    sysConfigResponse.widthPicture
                  }}</span>
            </td>
          </tr>

          <tr class="disable-row">
            <td class="table-index">81</td>
            <td>Chèn tiêu đề vào nội dung tin nhắn?</td>
            <td class="table-action">
                <span
                    v-if="sysConfigResponse.showTitleSms"
                >Có</span
                >
              <span v-else>Không</span>
            </td>
          </tr>

          <tr>
            <td class="table-index disable-row">82</td>
            <td class="disable-row">
              Nội dung tiêu đề mặc định trong tin nhắn SMS?
            </td>
            <td class="table-action">
              <el-popover
                  placement="top-start"
                  title="Nội dung tiêu đề"
                  width="300"
                  trigger="hover"
                  :content="
                    sysConfigResponse.titleContentSms
                  "
              >
                <el-button slot="reference" type="text">Xem</el-button>
              </el-popover>
            </td>
          </tr>
          <!-- Cường thiết lập thời gian đón muộn-->
          <tr>
            <td class="table-index">83</td>
            <td>Thiết lập thời gian đón muộn</td>
            <td class="table-action">
              <table class="center-table">
                <tr>
                  <td>
                    <el-radio-group
                        v-model="schoolConfigInSysResponse.statusBlock"
                    >
                      <el-radio :label="true">Block</el-radio>
                      <el-radio :label="false">Ngày</el-radio>
                    </el-radio-group>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style="margin-bottom: 10px">Nhập số phút</span>
                    <el-input-number
                        v-model="schoolConfigInSysResponse.blockLeaveKid"
                        style="width: 200px"
                        :min="0"
                        :step="1"
                        placeholder="Nhập số phút">
                      >
                    </el-input-number>
                  </td>
                </tr>
                <tr>
                  <td>
                    <el-radio-group v-model="schoolConfigInSysResponse.statusUpDown">
                      <el-radio :label="true">Làm tròn lên</el-radio>
                      <el-radio :label="false">Làm tròn xuống</el-radio>
                    </el-radio-group>
                  </td>
                </tr>
              </table>
            </td>
          </tr>

          <tr>
            <td class="table-index">84</td>
            <td>Cấu hình thứ tự hiển thị tin tức màn Home trên App?</td>
            <td>

              <div class="sttShowTabHomeTable">
                <table>
                  <tr>
                    <td style="text-align: center">Tin tức</td>
                    <td style="text-align: center">Facebook</td>
                    <td style="text-align: center">Video</td>
                    <td style="text-align: center">Website trường</td>
                    <td style="text-align: center">Tiktok</td>
                    <td style="text-align: center">Onekids Page</td>
                  </tr>
                  <tr>
                    <td style="text-align: center">
                      <el-checkbox
                          v-model="schoolConfigInSysResponse.activeShowNews"
                          :disabled="true"
                      ></el-checkbox>
                    </td>
                    <td style="text-align: center">
                      <el-checkbox
                          @change="changeShowFacebook"
                          v-model="schoolConfigInSysResponse.activeShowFacebook"
                      ></el-checkbox>
                    </td>
                    <td style="text-align: center">
                      <el-checkbox
                          @change="changeShowVideo"
                          v-model="schoolConfigInSysResponse.activeShowVideo"
                      ></el-checkbox>
                    </td>
                    <td style="text-align: center">
                      <el-checkbox
                          @change="changeShowWebsiteSchool"
                          v-model="schoolConfigInSysResponse.activeShowWebsiteSchool"
                      ></el-checkbox>
                    </td>
                    <td style="text-align: center">
                      <el-checkbox
                          @change="changeShowTiktok"
                          v-model="schoolConfigInSysResponse.activeShowTiktok"
                      ></el-checkbox>
                    </td>
                    <td style="text-align: center">
                      <el-checkbox
                          @change="changeShowOneKidsPage"
                          v-model="schoolConfigInSysResponse.activeShowOneKidsPage"
                      ></el-checkbox>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <el-select
                          class="input-common"
                          @change="changeNumberShowNews"
                          v-model="schoolConfigInSysResponse.sttShowNews"
                          placeholder="Chọn thứ tự hiển thị tin tức"
                      >
                        <el-option
                            v-for="item in numberSelect"
                            :key="item"
                            :disabled="item === schoolConfigInSysResponse.sttShowNews"
                            :value="item"
                            :label="`${item}`"
                        ></el-option>
                      </el-select>
                    </td>


                    <td>
                      <el-select
                          class="input-common"
                          @change="changeNumberShowFacebook"
                          v-model="schoolConfigInSysResponse.sttShowFacebook"
                          placeholder="Chọn thứ tự hiển thị Facebook"
                          :disabled="!schoolConfigInSysResponse.activeShowFacebook"
                      >
                        <el-option
                            v-for="item in numberSelect"
                            :key="item"
                            :disabled="item === schoolConfigInSysResponse.sttShowFacebook"
                            :value="item"
                            :label="`${item}`"
                        ></el-option>
                      </el-select>
                    </td>

                    <td>
                      <el-select
                          class="input-common"
                          @change="changeNumberShowVideo"
                          v-model="schoolConfigInSysResponse.sttShowVideo"
                          placeholder="Chọn thứ tự hiển thị Video"
                          :disabled="!schoolConfigInSysResponse.activeShowVideo"
                      >
                        <el-option
                            v-for="item in numberSelect"
                            :key="item"
                            :disabled="item === schoolConfigInSysResponse.sttShowVideo"
                            :value="item"
                            :label="`${item}`"
                        ></el-option>
                      </el-select>
                    </td>
                    <td>
                      <el-select
                          class="input-common"
                          v-model="schoolConfigInSysResponse.sttShowWebsiteSchool"
                          @change="changeNumberShowWebsiteSchool"
                          placeholder="Chọn thứ tự hiển thị trang web của trường"
                          :disabled="!schoolConfigInSysResponse.activeShowWebsiteSchool"
                      >
                        <el-option
                            v-for="item in numberSelect"
                            :key="item"
                            :disabled="item === schoolConfigInSysResponse.sttShowWebsiteSchool"
                            :value="item"
                            :label="`${item}`"
                        ></el-option>
                      </el-select>
                    </td>
                    <td>
                      <el-select
                          class="input-common"
                          @change="changeNumberShowTiktok"
                          v-model="schoolConfigInSysResponse.sttShowTiktok"
                          placeholder="Chọn thứ tự hiển thị Tiktok"
                          :disabled="!schoolConfigInSysResponse.activeShowTiktok"
                      >
                        <el-option
                            v-for="item in numberSelect"
                            :key="item"
                            :disabled="item === schoolConfigInSysResponse.sttShowTiktok"
                            :value="item"
                            :label="`${item}`"
                        ></el-option>
                      </el-select>
                    </td>
                    <td>
                      <el-select
                          class="input-common"
                          @change="changeNumberShowOneKidsPage"
                          v-model="schoolConfigInSysResponse.sttShowOneKidsPage"
                          placeholder="Chọn thứ tự hiển thị trang web của Onekids"
                          :disabled="!schoolConfigInSysResponse.activeShowOneKidsPage"
                      >
                        <el-option
                            v-for="item in numberSelect"
                            :key="item"
                            :disabled="item === schoolConfigInSysResponse.sttShowOneKidsPage"
                            :value="item"
                            :label="`${item}`"
                        ></el-option>
                      </el-select>
                    </td>
                  </tr>

                </table>

              </div>

            </td>
          </tr>

          <tr>
            <td class="table-index">85</td>
            <td>Có tự động xóa dữ liệu điểm danh, nhận xét trùng hay không?</td>
            <td class="table-action">
              <el-radio-group
                  v-model="schoolConfigInSysResponse.attendanceDuplicate"
              >
                <el-radio :label="true">Có</el-radio>
                <el-radio :label="false">Không</el-radio>
              </el-radio-group>
            </td>
          </tr>

          </tbody>
        </table>
      </div>
      <div>
        <el-button
            class="button-click"
            style="border-radius: 0"
            @click="submitConfigCommon()"
            :loading="loaddingButton"
            size="medium"
            type="success"
        >
          <i class="el-icon-circle-check"/>
          <span>Lưu</span>
        </el-button>
      </div>
    </div>
  </div>

</template>

<script>
import SchoolConfigService from "@/services/SchoolService/SchoolConfigService";

export default {

  data() {
    return {
      showDataSearch: false,
      configSchoolClassList: [],
      noSelect: "",
      albumRadio: false,
      loaddingButton: false,
      loaddingButtonClass: false,
      gradeList: [],
      classFirstGradeList: [],
      classList: [],
      dataSearch: {
        idGrade: "",
        idClass: "",
        className: "",
      },
      numberSelect: 6,
      numberOld: 0,
      sttShowNews: 0,
      sttShowFacebook: 0,
      sttShowVideo: 0,
      sttShowWebsiteSchool: 0,
      sttShowTiktok: 0,
      sttShowOneKidsPage: 0,
      schoolConfigInSysResponse: {
        evaluateWeek: undefined,
        editAproved: undefined,
        evaluateMonth: undefined,
        evaluatePeriod: undefined,
        approvedAttendanceArrive: undefined,
        timeAttendanceArrive: undefined,
      },
      sysConfigResponse: {},
      idSchool: 0,
      maxCountShow: 6,
    };
  },
  methods: {
    checkActive() {
      let countActive = 0;
      const activeShowNews = this.schoolConfigInSysResponse.activeShowNews;
      const activeShowFacebook = this.schoolConfigInSysResponse.activeShowFacebook;
      const activeShowVideo = this.schoolConfigInSysResponse.activeShowVideo;
      const activeShowTiktok = this.schoolConfigInSysResponse.activeShowTiktok;
      const activeShowWebsiteSchool = this.schoolConfigInSysResponse.activeShowWebsiteSchool;
      const activeShowOneKidsPage = this.schoolConfigInSysResponse.activeShowOneKidsPage;

      if (activeShowNews) {
        ++countActive;
      }

      if (activeShowFacebook) {
        ++countActive;
      }

      if (activeShowVideo) {
        ++countActive;
      }

      if (activeShowTiktok) {
        ++countActive;
      }

      if (activeShowWebsiteSchool) {
        ++countActive;
      }

      if (activeShowOneKidsPage) {
        ++countActive;
      }

      return countActive;
    },

    async changeNumberShowNews(value) {
      const indexShowNews = this.sttShowNews;
      const indexShowFacebook = this.schoolConfigInSysResponse.sttShowFacebook;
      const indexShowVideo = this.schoolConfigInSysResponse.sttShowVideo;
      const indexShowWebsiteSchool = this.schoolConfigInSysResponse.sttShowWebsiteSchool;
      const indexShowTiktok = this.schoolConfigInSysResponse.sttShowTiktok;
      const indexShowOneKidsPage = this.schoolConfigInSysResponse.sttShowOneKidsPage;

      if (indexShowFacebook === value) {
        this.schoolConfigInSysResponse.sttShowFacebook = indexShowNews;
        this.sttShowFacebook = indexShowNews;
      }

      if (indexShowTiktok === value) {
        this.schoolConfigInSysResponse.sttShowTiktok = indexShowNews;
        this.sttShowFacebook = indexShowNews;
      }

      if (indexShowVideo === value) {
        this.schoolConfigInSysResponse.sttShowVideo = indexShowNews;
        this.sttShowVideo = indexShowNews;
      }

      if (indexShowWebsiteSchool === value) {
        this.schoolConfigInSysResponse.sttShowWebsiteSchool = indexShowNews;
        this.sttShowWebsiteSchool = indexShowNews;
      }

      if (indexShowOneKidsPage === value) {
        this.schoolConfigInSysResponse.sttShowOneKidsPage = indexShowNews;
        this.sttShowOneKidsPage = indexShowNews;
      }

      this.schoolConfigInSysResponse.sttShowNews = value;
      this.sttShowNews = value;
    },

    async changeNumberShowFacebook(value) {
      const indexShowNews = this.schoolConfigInSysResponse.sttShowNews;
      const indexShowFacebook = this.sttShowFacebook;
      const indexShowVideo = this.schoolConfigInSysResponse.sttShowVideo;
      const indexShowWebsiteSchool = this.schoolConfigInSysResponse.sttShowWebsiteSchool;
      const indexShowTiktok = this.schoolConfigInSysResponse.sttShowTiktok;
      const indexShowOneKidsPage = this.schoolConfigInSysResponse.sttShowOneKidsPage;

      if (indexShowNews === value) {
        this.schoolConfigInSysResponse.sttShowNews = indexShowFacebook;
        this.sttShowNews = indexShowFacebook;
      }

      if (indexShowTiktok === value) {
        this.schoolConfigInSysResponse.sttShowTiktok = indexShowFacebook;
        this.sttShowTiktok = indexShowFacebook;
      }

      if (indexShowVideo === value) {
        this.schoolConfigInSysResponse.sttShowVideo = indexShowFacebook;
        this.sttShowVideo = indexShowFacebook;
      }

      if (indexShowWebsiteSchool === value) {
        this.schoolConfigInSysResponse.sttShowWebsiteSchool = indexShowFacebook;
        this.sttShowWebsiteSchool = indexShowFacebook;
      }

      if (indexShowOneKidsPage === value) {
        this.schoolConfigInSysResponse.sttShowOneKidsPage = indexShowFacebook;
        this.sttShowOneKidsPage = indexShowFacebook;
      }

      this.schoolConfigInSysResponse.sttShowFacebook = value;
      this.sttShowFacebook = value;
    },

    async changeNumberShowVideo(value) {
      const indexShowNews = this.schoolConfigInSysResponse.sttShowNews;
      const indexShowFacebook = this.schoolConfigInSysResponse.sttShowFacebook;
      const indexShowVideo = this.sttShowVideo;
      const indexShowWebsiteSchool = this.schoolConfigInSysResponse.sttShowWebsiteSchool;
      const indexShowTiktok = this.schoolConfigInSysResponse.sttShowTiktok;
      const indexShowOneKidsPage = this.schoolConfigInSysResponse.sttShowOneKidsPage;

      if (indexShowNews === value) {
        this.schoolConfigInSysResponse.sttShowNews = indexShowVideo;
        this.sttShowNews = indexShowVideo;
      }

      if (indexShowFacebook === value) {
        this.schoolConfigInSysResponse.sttShowFacebook = indexShowVideo;
        this.sttShowFacebook = indexShowVideo;
      }

      if (indexShowTiktok === value) {
        this.schoolConfigInSysResponse.sttShowTiktok = indexShowVideo;
        this.sttShowTiktok = indexShowVideo;
      }

      if (indexShowWebsiteSchool === value) {
        this.schoolConfigInSysResponse.sttShowWebsiteSchool = indexShowVideo;
        this.sttShowWebsiteSchool = indexShowVideo;
      }

      if (indexShowOneKidsPage === value) {
        this.schoolConfigInSysResponse.sttShowOneKidsPage = indexShowVideo;
        this.sttShowOneKidsPage = indexShowVideo;
      }

      this.schoolConfigInSysResponse.sttShowVideo = value;
      this.sttShowVideo = value;
    },

    async changeNumberShowWebsiteSchool(value) {
      const indexShowNews = this.schoolConfigInSysResponse.sttShowNews;
      const indexShowFacebook = this.schoolConfigInSysResponse.sttShowFacebook;
      const indexShowVideo = this.schoolConfigInSysResponse.sttShowVideo;
      const indexShowWebsiteSchool = this.sttShowWebsiteSchool;
      const indexShowTiktok = this.schoolConfigInSysResponse.sttShowTiktok;
      const indexShowOneKidsPage = this.schoolConfigInSysResponse.sttShowOneKidsPage;

      if (indexShowNews === value) {
        this.schoolConfigInSysResponse.sttShowNews = indexShowWebsiteSchool;
        this.sttShowNews = indexShowWebsiteSchool;
      }

      if (indexShowFacebook === value) {
        this.schoolConfigInSysResponse.sttShowFacebook = indexShowWebsiteSchool;
        this.sttShowFacebook = indexShowWebsiteSchool;
      }

      if (indexShowVideo === value) {
        this.schoolConfigInSysResponse.sttShowVideo = indexShowWebsiteSchool;
        this.sttShowVideo = indexShowWebsiteSchool;
      }

      if (indexShowTiktok === value) {
        this.schoolConfigInSysResponse.sttShowTiktok = indexShowWebsiteSchool;
        this.sttShowTiktok = indexShowWebsiteSchool;
      }

      if (indexShowOneKidsPage === value) {
        this.schoolConfigInSysResponse.sttShowOneKidsPage = indexShowWebsiteSchool;
        this.sttShowOneKidsPage = indexShowWebsiteSchool;
      }

      this.schoolConfigInSysResponse.sttShowWebsiteSchool = value;
      this.sttShowWebsiteSchool = value;
    },

    async changeNumberShowTiktok(value) {
      const indexShowNews = this.schoolConfigInSysResponse.sttShowNews;
      const indexShowFacebook = this.schoolConfigInSysResponse.sttShowFacebook;
      const indexShowVideo = this.schoolConfigInSysResponse.sttShowVideo;
      const indexShowWebsiteSchool = this.schoolConfigInSysResponse.sttShowWebsiteSchool;
      const indexShowTiktok = this.sttShowTiktok;
      const indexShowOneKidsPage = this.schoolConfigInSysResponse.sttShowOneKidsPage;

      if (indexShowNews === value) {
        this.schoolConfigInSysResponse.sttShowNews = indexShowTiktok;
        this.sttShowNews = indexShowTiktok;
      }

      if (indexShowFacebook === value) {
        this.schoolConfigInSysResponse.sttShowFacebook = indexShowTiktok;
        this.sttShowFacebook = indexShowTiktok;
      }

      if (indexShowVideo === value) {
        this.schoolConfigInSysResponse.sttShowVideo = indexShowTiktok;
        this.sttShowVideo = indexShowTiktok;
      }

      if (indexShowWebsiteSchool === value) {
        this.schoolConfigInSysResponse.sttShowWebsiteSchool = indexShowTiktok;
        this.sttShowWebsiteSchool = indexShowTiktok;
      }

      if (indexShowOneKidsPage === value) {
        this.schoolConfigInSysResponse.sttShowOneKidsPage = indexShowTiktok;
        this.sttShowOneKidsPage = indexShowTiktok;
      }

      this.schoolConfigInSysResponse.sttShowTiktok = value;
      this.sttShowTiktok = value;
    },

    async changeNumberShowOneKidsPage(value) {
      const indexShowNews = this.schoolConfigInSysResponse.sttShowNews;
      const indexShowFacebook = this.schoolConfigInSysResponse.sttShowFacebook;
      const indexShowVideo = this.schoolConfigInSysResponse.sttShowVideo;
      const indexShowWebsiteSchool = this.schoolConfigInSysResponse.sttShowWebsiteSchool;
      const indexShowTiktok = this.schoolConfigInSysResponse.sttShowTiktok;
      const indexShowOneKidsPage = this.sttShowOneKidsPage

      if (indexShowNews === value) {
        this.schoolConfigInSysResponse.sttShowNews = indexShowOneKidsPage;
        this.sttShowNews = indexShowOneKidsPage;
      }

      if (indexShowFacebook === value) {
        this.schoolConfigInSysResponse.sttShowFacebook = indexShowOneKidsPage;
        this.sttShowFacebook = indexShowOneKidsPage;
      }

      if (indexShowVideo === value) {
        this.schoolConfigInSysResponse.sttShowVideo = indexShowOneKidsPage;
        this.sttShowVideo = indexShowOneKidsPage;
      }

      if (indexShowWebsiteSchool === value) {
        this.schoolConfigInSysResponse.sttShowWebsiteSchool = indexShowOneKidsPage;
        this.sttShowWebsiteSchool = indexShowOneKidsPage;
      }

      if (indexShowTiktok === value) {
        this.schoolConfigInSysResponse.sttShowTiktok = indexShowOneKidsPage;
        this.sttShowTiktok = indexShowOneKidsPage;
      }

      this.schoolConfigInSysResponse.sttShowOneKidsPage = value;
      this.sttShowOneKidsPage = value;
    },

    async changeNumberSelect() {
      this.numberSelect = 6
      if (this.schoolConfigInSysResponse.activeShowWebsiteSchool === false) {
        this.numberSelect--
      }
      if (this.schoolConfigInSysResponse.activeShowTiktok === false) {
        this.numberSelect--
      }
      if (this.schoolConfigInSysResponse.activeShowOneKidsPage === false) {
        this.numberSelect--
      }
    },

    changeShowFacebook(value) {
      const countActive = this.checkActive();
      const indexShowNews = this.schoolConfigInSysResponse.sttShowNews;
      const indexShowFacebook = this.schoolConfigInSysResponse.sttShowFacebook;
      const indexShowVideo = this.schoolConfigInSysResponse.sttShowVideo;
      const indexShowWebsiteSchool = this.schoolConfigInSysResponse.sttShowWebsiteSchool;
      const indexShowTiktok = this.schoolConfigInSysResponse.sttShowTiktok;
      const indexShowOneKidsPage = this.schoolConfigInSysResponse.sttShowOneKidsPage;

      this.schoolConfigInSysResponse.activeShowFacebook = value;

      if(!value) {
        --this.numberSelect;

        if(indexShowNews > indexShowFacebook) {
          --this.schoolConfigInSysResponse.sttShowNews;
          --this.sttShowNews;
        }

        if(indexShowVideo > indexShowFacebook) {
          --this.schoolConfigInSysResponse.sttShowVideo;
          --this.sttShowVideo;
        }

        if(indexShowWebsiteSchool > indexShowFacebook) {
          --this.schoolConfigInSysResponse.sttShowWebsiteSchool;
          --this.sttShowWebsiteSchool;
        }

        if(indexShowTiktok > indexShowFacebook) {
          --this.schoolConfigInSysResponse.sttShowTiktok;
          --this.sttShowTiktok;
        }

        if(indexShowOneKidsPage > indexShowFacebook) {
          --this.schoolConfigInSysResponse.sttShowOneKidsPage;
          --this.sttShowOneKidsPage;
        }

        this.schoolConfigInSysResponse.sttShowFacebook = this.maxCountShow;
        this.sttShowFacebook = this.maxCountShow;

      } else {

        if (indexShowNews === countActive) {
          this.schoolConfigInSysResponse.sttShowNews = indexShowFacebook;
          this.sttShowNews = indexShowFacebook;
        }

        if (indexShowTiktok === countActive) {
          this.schoolConfigInSysResponse.sttShowTiktok = indexShowFacebook;
          this.sttShowTiktok = indexShowFacebook;
        }

        if (indexShowVideo === countActive) {
          this.schoolConfigInSysResponse.sttShowVideo = indexShowFacebook;
          this.sttShowVideo = indexShowFacebook;
        }

        if (indexShowWebsiteSchool === countActive) {
          this.schoolConfigInSysResponse.sttShowWebsiteSchool = indexShowFacebook;
          this.sttShowWebsiteSchool = indexShowFacebook;
        }

        if (indexShowOneKidsPage === countActive) {
          this.schoolConfigInSysResponse.sttShowOneKidsPage = indexShowFacebook;
          this.sttShowOneKidsPage = indexShowFacebook;
        }
        this.schoolConfigInSysResponse.sttShowFacebook = countActive;
        this.sttShowFacebook = countActive;
        ++this.numberSelect;
      }
    },

    changeShowVideo(value) {
      const countActive = this.checkActive();
      const indexShowNews = this.schoolConfigInSysResponse.sttShowNews;
      const indexShowFacebook = this.schoolConfigInSysResponse.sttShowFacebook;
      const indexShowVideo = this.schoolConfigInSysResponse.sttShowVideo;
      const indexShowWebsiteSchool = this.schoolConfigInSysResponse.sttShowWebsiteSchool;
      const indexShowTiktok = this.schoolConfigInSysResponse.sttShowTiktok;
      const indexShowOneKidsPage = this.schoolConfigInSysResponse.sttShowOneKidsPage;

      this.schoolConfigInSysResponse.activeShowVideo = value;

      if(!value) {
        --this.numberSelect;

        if(indexShowNews > indexShowVideo) {
          --this.schoolConfigInSysResponse.sttShowNews;
          --this.sttShowNews;
        }

        if(indexShowFacebook > indexShowVideo) {
          --this.schoolConfigInSysResponse.sttShowFacebook;
          --this.sttShowFacebook;
        }

        if(indexShowWebsiteSchool > indexShowVideo) {
          --this.schoolConfigInSysResponse.sttShowWebsiteSchool;
          --this.sttShowWebsiteSchool;
        }

        if(indexShowTiktok> indexShowVideo) {
          --this.schoolConfigInSysResponse.sttShowTiktok;
          --this.sttShowTiktok;
        }

        if(indexShowOneKidsPage> indexShowVideo) {
          --this.schoolConfigInSysResponse.sttShowOneKidsPage;
          --this.sttShowOneKidsPage;
        }

        this.schoolConfigInSysResponse.sttShowVideo = this.maxCountShow;
        this.sttShowVideo = this.maxCountShow;

      } else {

        if (indexShowNews === countActive) {
          this.schoolConfigInSysResponse.sttShowNews = indexShowVideo;
          this.sttShowNews = indexShowVideo;
        }

        if (indexShowTiktok === countActive) {
          this.schoolConfigInSysResponse.sttShowTiktok = indexShowVideo;
          this.sttShowTiktok = indexShowVideo;
        }

        if (indexShowFacebook === countActive) {
          this.schoolConfigInSysResponse.sttShowFacebook = indexShowVideo;
          this.sttShowFacebook = indexShowVideo;
        }

        if (indexShowWebsiteSchool === countActive) {
          this.schoolConfigInSysResponse.sttShowWebsiteSchool = indexShowVideo;
          this.sttShowWebsiteSchool = indexShowVideo;
        }

        if (indexShowOneKidsPage === countActive) {
          this.schoolConfigInSysResponse.sttShowOneKidsPage = indexShowVideo;
          this.sttShowOneKidsPage = indexShowVideo;
        }
        this.schoolConfigInSysResponse.sttShowVideo = countActive;
        this.sttShowVideo = countActive;
        ++this.numberSelect;
      }
    },

    changeShowWebsiteSchool(value) {
      const countActive = this.checkActive();
      const indexShowNews = this.schoolConfigInSysResponse.sttShowNews;
      const indexShowFacebook = this.schoolConfigInSysResponse.sttShowFacebook;
      const indexShowVideo = this.schoolConfigInSysResponse.sttShowVideo;
      const indexShowWebsiteSchool = this.schoolConfigInSysResponse.sttShowWebsiteSchool;
      const indexShowTiktok = this.schoolConfigInSysResponse.sttShowTiktok;
      const indexShowOneKidsPage = this.schoolConfigInSysResponse.sttShowOneKidsPage;

      this.schoolConfigInSysResponse.activeShowWebsiteShool = value;

      if(!value) {
        --this.numberSelect;

        if(indexShowNews > indexShowWebsiteSchool) {
          --this.schoolConfigInSysResponse.sttShowNews;
          --this.sttShowNews;
        }

        if(indexShowVideo > indexShowWebsiteSchool) {
          --this.schoolConfigInSysResponse.sttShowVideo;
          --this.sttShowVideo;
        }

        if(indexShowFacebook > indexShowWebsiteSchool) {
          --this.schoolConfigInSysResponse.sttShowFacebook;
          --this.sttShowFacebook;
        }

        if(indexShowTiktok> indexShowWebsiteSchool) {
          --this.schoolConfigInSysResponse.sttShowTiktok;
          --this.sttShowTiktok;
        }

        if(indexShowOneKidsPage> indexShowWebsiteSchool) {
          --this.schoolConfigInSysResponse.sttShowOneKidsPage;
          --this.sttShowOneKidsPage;
        }

        this.schoolConfigInSysResponse.sttShowWebsiteSchool = this.maxCountShow;
        this.sttShowWebsiteSchool = this.maxCountShow;

      } else {

        if (indexShowNews === countActive) {
          this.schoolConfigInSysResponse.sttShowNews = indexShowWebsiteSchool;
          this.sttShowNews = indexShowWebsiteSchool;
        }

        if (indexShowTiktok === countActive) {
          this.schoolConfigInSysResponse.sttShowTiktok = indexShowWebsiteSchool;
          this.sttShowTiktok = indexShowWebsiteSchool;
        }

        if (indexShowFacebook === countActive) {
          this.schoolConfigInSysResponse.sttShowFacebook = indexShowWebsiteSchool;
          this.sttShowFacebook = indexShowWebsiteSchool;
        }

        if (indexShowVideo === countActive) {
          this.schoolConfigInSysResponse.sttShowVideo = indexShowWebsiteSchool;
          this.sttShowVideo = indexShowWebsiteSchool;
        }

        if (indexShowOneKidsPage === countActive) {
          this.schoolConfigInSysResponse.sttShowOneKidsPage = indexShowWebsiteSchool;
          this.sttShowOneKidsPage = indexShowWebsiteSchool;
        }
        this.schoolConfigInSysResponse.sttShowWebsiteSchool = countActive;
        this.sttShowWebsiteSchool = countActive;
        ++this.numberSelect
      }
    },

    changeShowTiktok(value) {
      const countActive = this.checkActive();
      const indexShowNews = this.schoolConfigInSysResponse.sttShowNews;
      const indexShowFacebook = this.schoolConfigInSysResponse.sttShowFacebook;
      const indexShowVideo = this.schoolConfigInSysResponse.sttShowVideo;
      const indexShowWebsiteSchool = this.schoolConfigInSysResponse.sttShowWebsiteSchool;
      const indexShowTiktok = this.schoolConfigInSysResponse.sttShowTiktok;
      const indexShowOneKidsPage = this.schoolConfigInSysResponse.sttShowOneKidsPage;

      this.schoolConfigInSysResponse.activeShowTiktok = value;

      if(!value) {
        --this.numberSelect;

        if(indexShowNews > indexShowTiktok) {
          --this.schoolConfigInSysResponse.sttShowNews;
          --this.sttShowNews;
        }

        if(indexShowVideo > indexShowTiktok) {
          --this.schoolConfigInSysResponse.sttShowVideo;
          --this.sttShowVideo;
        }

        if(indexShowWebsiteSchool > indexShowTiktok) {
          --this.schoolConfigInSysResponse.sttShowWebsiteSchool;
          --this.sttShowWebsiteSchool;
        }

        if(indexShowFacebook > indexShowTiktok) {
          --this.schoolConfigInSysResponse.sttShowFacebook;
          --this.sttShowFacebook;
        }

        if(indexShowOneKidsPage > indexShowTiktok) {
          --this.schoolConfigInSysResponse.sttShowOneKidsPage;
          --this.sttShowOneKidsPage;
        }

        this.schoolConfigInSysResponse.sttShowTiktok = this.maxCountShow;
        this.sttShowTiktok = this.maxCountShow;
      } else {

        if (indexShowNews === countActive) {
          this.schoolConfigInSysResponse.sttShowNews = indexShowTiktok;
          this.sttShowNews = indexShowTiktok;
        }

        if (indexShowWebsiteSchool === countActive) {
          this.schoolConfigInSysResponse.sttShowWebsiteSchool = indexShowTiktok;
          this.sttShowWebsiteSchool = indexShowTiktok;
        }

        if (indexShowFacebook === countActive) {
          this.schoolConfigInSysResponse.sttShowFacebook = indexShowTiktok;
          this.sttShowFacebook = indexShowTiktok;
        }

        if (indexShowVideo === countActive) {
          this.schoolConfigInSysResponse.sttShowVideo = indexShowTiktok;
          this.sttShowVideo = indexShowTiktok;
        }

        if (indexShowOneKidsPage === countActive) {
          this.schoolConfigInSysResponse.sttShowOneKidsPage = indexShowTiktok;
          this.sttShowOneKidsPage = indexShowTiktok;
        }
        this.schoolConfigInSysResponse.sttShowTiktok = countActive;
        this.sttShowTiktok = countActive;
        ++this.numberSelect;
      }
    },

    changeShowOneKidsPage(value) {
      const countActive = this.checkActive();
      const indexShowNews = this.schoolConfigInSysResponse.sttShowNews;
      const indexShowFacebook = this.schoolConfigInSysResponse.sttShowFacebook;
      const indexShowVideo = this.schoolConfigInSysResponse.sttShowVideo;
      const indexShowWebsiteSchool = this.schoolConfigInSysResponse.sttShowWebsiteSchool;
      const indexShowTiktok = this.schoolConfigInSysResponse.sttShowTiktok;
      const indexShowOneKidsPage = this.schoolConfigInSysResponse.sttShowOneKidsPage;

      this.schoolConfigInSysResponse.activeShowOneKidsPage = value;

      if(!value) {
        --this.numberSelect;

        if(indexShowNews > indexShowOneKidsPage) {
          --this.schoolConfigInSysResponse.sttShowNews;
          --this.sttShowNews;
        }

        if(indexShowVideo > indexShowOneKidsPage) {
          --this.schoolConfigInSysResponse.sttShowVideo;
          --this.sttShowVideo;
        }

        if(indexShowWebsiteSchool > indexShowOneKidsPage) {
          --this.schoolConfigInSysResponse.sttShowWebsiteSchool;
          --this.sttShowWebsiteSchool;
        }

        if(indexShowFacebook > indexShowOneKidsPage) {
          --this.schoolConfigInSysResponse.sttShowFacebook;
          --this.sttShowFacebook;
        }

        if(indexShowTiktok > indexShowOneKidsPage) {
          --this.schoolConfigInSysResponse.sttShowTiktok;
          --this.sttShowTiktok;
        }

        this.schoolConfigInSysResponse.sttShowOneKidsPage = this.maxCountShow;
        this.sttShowOneKidsPage = this.maxCountShow;

      } else {

        if (indexShowNews === countActive) {
          this.schoolConfigInSysResponse.sttShowNews = indexShowOneKidsPage;
          this.sttShowNews = indexShowOneKidsPage;
        }

        if (indexShowWebsiteSchool === countActive) {
          this.schoolConfigInSysResponse.sttShowWebsiteSchool = indexShowOneKidsPage;
          this.sttShowWebsiteSchool = indexShowOneKidsPage;
        }

        if (indexShowFacebook === countActive) {
          this.schoolConfigInSysResponse.sttShowFacebook = indexShowOneKidsPage;
          this.sttShowFacebook = indexShowOneKidsPage;
        }

        if (indexShowVideo === countActive) {
          this.schoolConfigInSysResponse.sttShowVideo = indexShowOneKidsPage;
          this.sttShowVideo = indexShowOneKidsPage;
        }

        if (indexShowTiktok === countActive) {
          this.schoolConfigInSysResponse.sttShowTiktok = indexShowOneKidsPage;
          this.sttShowTiktok = indexShowOneKidsPage;
        }
        this.schoolConfigInSysResponse.sttShowOneKidsPage = countActive;
        this.sttShowOneKidsPage = countActive;
        ++this.numberSelect;
      }
    },

    tableHeaderColor() {
      return "background-color: #78a5e7;color: #fff;font-weight: bold;";
    },
    closeSettingDayOffMethod() {
      this.showSettingDayOff = false;
    },
    settingDayOffClassMethod(row) {
      this.showSettingDayOff = true;
      this.$refs.DayOffClassDialog.getDataInitial(row);
    },
    /**
     * lưu cấu hình chung
     */
    submitConfigCommon() {
      this.loaddingButton = true;
      const data = {
        schoolConfigInSysResponse: this.schoolConfigInSysResponse,
        sysConfigResponse: this.sysConfigResponse,
        idSchool: this.idSchool
      }
      SchoolConfigService.updateConfigCommon(data)
          .then((resp) => {
            this.$message({
              message: resp.data.message,
              type: "success",
            });
          })
          .catch((err) => {
            console.log(err);
            this.$message({
              message: err.response.data.message,
              type: "error",
            });
          })
          .finally(() => {
            setTimeout(() => {
              this.loaddingButton = false;
            }, 500);
          });

    },
    /**
     * lấy cấu hình chung
     */
    getConfigCommonMethod() {
      SchoolConfigService.getConfigCommon()
          .then((resp) => {
            this.idSchool = resp.data.data.idSchool
            this.sysConfigResponse = resp.data.data.sysConfigResponse
            this.schoolConfigInSysResponse = resp.data.data.schoolConfigInSysResponse
            this.sttShowNews = this.schoolConfigInSysResponse.sttShowNews
            this.sttShowFacebook = this.schoolConfigInSysResponse.sttShowFacebook
            this.sttShowVideo = this.schoolConfigInSysResponse.sttShowVideo
            this.sttShowWebsiteSchool = this.schoolConfigInSysResponse.sttShowWebsiteSchool
            this.sttShowTiktok = this.schoolConfigInSysResponse.sttShowTiktok
            this.sttShowOneKidsPage = this.schoolConfigInSysResponse.sttShowOneKidsPage
            this.changeNumberSelect()
          })
          .catch((err) => {
            console.log(err);
          });
    },

  }
  ,
  beforeMount() {
    this.getConfigCommonMethod();
    // setTimeout(() => {
    //   this.getAllGrade();
    //   this.getAllClass();
    // }, 1000);
  }
  ,
}
;
</script>

<style lang="scss" scoped>
/deep/ .el-tabs__nav {
  background: #a0a19ce0;
}

/deep/ .el-tabs__item {
  color: white;
}

/deep/ .el-tabs__item.is-active {
  color: white;
  background: #78a5e7;
}

/deep/ .el-form-item__label {
  font-family: Arial, Helvetica, sans-serif;
}

/deep/ .el-tabs__nav-scroll {
  float: right;
}

/deep/ .el-tabs__header {
  margin-bottom: 0;
}

/deep/ .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border-bottom-color: #78a5e7;
}

/deep/ .el-checkbox__inner {
  border: 1px solid gray;
}

.input-common {
  width: 60px
}

.button-bottom {
  border-radius: 0;
  margin-left: 3px;
}

.button-click {
  float: right;
  margin: 20px 0 40px 0;
}

.el-dropdown-menu {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 0;
  margin: 3px 0;
  background-color: #5fb44a;
  border: 1px solid #ebeef5;
  border-radius: 0;
  box-shadow: 0 2px 12px 0 #0000001a;
}

.el-dropdown-menu li {
  color: white;
  border-top: 1px solid white;
  font-family: Arial, Helvetica, sans-serif;
}

.over-table {
  width: 97%;
  margin: 0 auto;
}

.table-data {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ebeef5;
}

@media screen and (max-width: 1366px) {
  .wrapper-table {
    height: 490px;
  }
}

@media screen and (min-width: 1367px) {
  .wrapper-table {
    height: 760px;
  }
}

.wrapper-table {
  overflow-y: scroll;
  border-bottom: 1px solid #ebeef5;
}

@media screen and (max-width: 1366px) {
  .sttShowTabHomeTable {
    width: 300px;
  }
}

@media screen and (min-width: 1367px) {
  .sttShowTabHomeTable {
    width: 300px;
  }
}

.sttShowTabHomeTable {
  overflow-x: scroll;
  border-bottom: 1px solid #ebeef5;
}

thead {
  background: #78a5e7;
  color: white;
  font-weight: bold;
  // display: block;
  // width: 90%;
}

tbody {
  color: #606266;
  font-size: 14px;
  overflow: auto;
  // height: 600px;
  font-family: Arial, Helvetica, sans-serif;
  // display: block;
  // width: 90%;
}

td {
  padding: 12px;
}

.table-index {
  width: 10px;
  text-align: center;
}

.table-item-name {
  width: 200px;
  text-align: center;
}

.table-action {
  width: 300px;
  text-align: center;
}

.color-text-reply {
  color: #4177e2;
}

.input-data {
  width: 60%;
}

.disable-row {
  background: #e4e7ed;
}

.table-action1 {
  display: flex;
  justify-content: center; /* Căn giữa theo chiều ngang */
  align-items: center; /* Căn giữa theo chiều dọc */
}

.center-table {
  margin: auto; /* Đảm bảo bảng nằm giữa */
}

</style>
