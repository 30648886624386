var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _c("div", { staticClass: "wrapper-table" }, [
        _c("table", { staticClass: "table-data", attrs: { border: "" } }, [
          _vm._m(0),
          _c("tbody", [
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("1")]),
              _c("td", [_vm._v("Có cần duyệt album ảnh hay không?")]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.album,
                        callback: function ($$v) {
                          _vm.$set(_vm.schoolConfigInSysResponse, "album", $$v)
                        },
                        expression: "schoolConfigInSysResponse.album",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("2")]),
              _c("td", [_vm._v("Có cần duyệt đánh giá nhận xét hay không?")]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.evaluate,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "evaluate",
                            $$v
                          )
                        },
                        expression: "schoolConfigInSysResponse.evaluate",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("3")]),
              _c("td", [
                _vm._v("Có cho phép sửa đánh giá khi đã duyệt hay không?"),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.editAproved,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "editAproved",
                            $$v
                          )
                        },
                        expression: "schoolConfigInSysResponse.editAproved",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("4")]),
              _c("td", [
                _vm._v("Có hiển thị chức năng đánh giá ngày hay không?"),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.evaluateDate,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "evaluateDate",
                            $$v
                          )
                        },
                        expression: "schoolConfigInSysResponse.evaluateDate",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("5")]),
              _c("td", [
                _vm._v("Có hiển thị chức năng đánh giá tuần hay không?"),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.evaluateWeek,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "evaluateWeek",
                            $$v
                          )
                        },
                        expression: "schoolConfigInSysResponse.evaluateWeek",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("6")]),
              _c("td", [
                _vm._v("Có hiển thị chức năng đánh giá tháng hay không?"),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.evaluateMonth,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "evaluateMonth",
                            $$v
                          )
                        },
                        expression: "schoolConfigInSysResponse.evaluateMonth",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("7")]),
              _c("td", [
                _vm._v("Có hiển thị chức năng đánh giá định kỳ hay không?"),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.evaluatePeriod,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "evaluatePeriod",
                            $$v
                          )
                        },
                        expression: "schoolConfigInSysResponse.evaluatePeriod",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("8")]),
              _c("td", [_vm._v("Có gửi thông báo điểm danh đến hay không?")]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value:
                          _vm.schoolConfigInSysResponse
                            .approvedAttendanceArrive,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "approvedAttendanceArrive",
                            $$v
                          )
                        },
                        expression:
                          "schoolConfigInSysResponse.approvedAttendanceArrive",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("9")]),
              _c("td", [
                _vm._v("Giới hạn thời gian gửi điểm danh đến trước mấy giờ?"),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c("el-time-picker", {
                    staticClass: "input-data",
                    attrs: {
                      size: "mini",
                      clearable: false,
                      type: "time",
                      "value-format": "HH:mm",
                      format: "HH:mm",
                      "picker-options": {
                        selectableRange: "06:00:00 - 21:00:00",
                      },
                      placeholder: "Chọn",
                    },
                    model: {
                      value: _vm.schoolConfigInSysResponse.timeAttendanceArrive,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.schoolConfigInSysResponse,
                          "timeAttendanceArrive",
                          $$v
                        )
                      },
                      expression:
                        "schoolConfigInSysResponse.timeAttendanceArrive",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("10")]),
              _c("td", [_vm._v("Có gửi thông báo điểm danh về hay không?")]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value:
                          _vm.schoolConfigInSysResponse.approvedAttendanceLeave,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "approvedAttendanceLeave",
                            $$v
                          )
                        },
                        expression:
                          "schoolConfigInSysResponse.approvedAttendanceLeave",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("11")]),
              _c("td", [
                _vm._v("Giới hạn thời gian gửi điểm danh về trước mấy giờ?"),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c("el-time-picker", {
                    staticClass: "input-data",
                    attrs: {
                      size: "mini",
                      clearable: false,
                      type: "time",
                      "value-format": "HH:mm",
                      format: "HH:mm",
                      "picker-options": {
                        selectableRange: "06:00:00 - 21:00:00",
                      },
                      placeholder: "Chọn",
                    },
                    model: {
                      value: _vm.schoolConfigInSysResponse.timeAttendanceLeave,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.schoolConfigInSysResponse,
                          "timeAttendanceLeave",
                          $$v
                        )
                      },
                      expression:
                        "schoolConfigInSysResponse.timeAttendanceLeave",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("12")]),
              _c("td", [
                _vm._v("Cho phép điểm danh lại với học sinh mấy ngày trước?"),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c("el-input", {
                    staticClass: "input-data",
                    attrs: { size: "mini", placeholder: "Nhập số ngày" },
                    model: {
                      value: _vm.schoolConfigInSysResponse.againAttendance,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.schoolConfigInSysResponse,
                          "againAttendance",
                          $$v
                        )
                      },
                      expression: "schoolConfigInSysResponse.againAttendance",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("13")]),
              _c("td", [
                _vm._v(
                  "\n            Gửi đơn xin nghỉ phải trước ngày đầu tiên muốn nghỉ số ngày là?\n          "
                ),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c("el-input", {
                    staticClass: "input-data",
                    attrs: { size: "mini", placeholder: "Nhập số ngày" },
                    model: {
                      value: _vm.schoolConfigInSysResponse.dateAbsent,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.schoolConfigInSysResponse,
                          "dateAbsent",
                          $$v
                        )
                      },
                      expression: "schoolConfigInSysResponse.dateAbsent",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("14")]),
              _c("td", [_vm._v("Thời gian xin nghỉ phải trước mấy giờ?")]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c("el-time-picker", {
                    staticClass: "input-data",
                    attrs: {
                      size: "mini",
                      clearable: false,
                      type: "time",
                      "value-format": "HH:mm",
                      format: "HH:mm",
                      "picker-options": {
                        selectableRange: "06:00:00 - 21:00:00",
                      },
                      placeholder: "Chọn",
                    },
                    model: {
                      value: _vm.schoolConfigInSysResponse.timeAbsent,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.schoolConfigInSysResponse,
                          "timeAbsent",
                          $$v
                        )
                      },
                      expression: "schoolConfigInSysResponse.timeAbsent",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("15")]),
              _c("td", [_vm._v("Mặc định có học sáng thứ 7 hàng tuần không?")]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.morningSaturday,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "morningSaturday",
                            $$v
                          )
                        },
                        expression: "schoolConfigInSysResponse.morningSaturday",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("16")]),
              _c("td", [
                _vm._v("Mặc định có học chiều thứ 7 hàng tuần không?"),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.afternoonSaturday,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "afternoonSaturday",
                            $$v
                          )
                        },
                        expression:
                          "schoolConfigInSysResponse.afternoonSaturday",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("17")]),
              _c("td", [_vm._v("Mặc định có học tối thứ 7 hàng tuần không?")]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.eveningSaturday,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "eveningSaturday",
                            $$v
                          )
                        },
                        expression: "schoolConfigInSysResponse.eveningSaturday",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("18")]),
              _c("td", [_vm._v("Mặc định có học chủ nhật hàng tuần không?")]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.sunday,
                        callback: function ($$v) {
                          _vm.$set(_vm.schoolConfigInSysResponse, "sunday", $$v)
                        },
                        expression: "schoolConfigInSysResponse.sunday",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("19")]),
              _c("td", [_vm._v("Hiển thị điểm danh đi học buổi sáng không?")]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value:
                          _vm.schoolConfigInSysResponse.morningAttendanceArrive,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "morningAttendanceArrive",
                            $$v
                          )
                        },
                        expression:
                          "schoolConfigInSysResponse.morningAttendanceArrive",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("20")]),
              _c("td", [_vm._v("Hiển thị điểm danh đi học buổi chiều không?")]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value:
                          _vm.schoolConfigInSysResponse
                            .afternoonAttendanceArrive,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "afternoonAttendanceArrive",
                            $$v
                          )
                        },
                        expression:
                          "schoolConfigInSysResponse.afternoonAttendanceArrive",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("21")]),
              _c("td", [_vm._v("Hiển thị điểm danh đi học buổi tối không?")]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value:
                          _vm.schoolConfigInSysResponse.eveningAttendanceArrive,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "eveningAttendanceArrive",
                            $$v
                          )
                        },
                        expression:
                          "schoolConfigInSysResponse.eveningAttendanceArrive",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("22")]),
              _c("td", [_vm._v("Hiển thị điểm danh ăn bữa sáng không?")]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.morningEat,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "morningEat",
                            $$v
                          )
                        },
                        expression: "schoolConfigInSysResponse.morningEat",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("23")]),
              _c("td", [_vm._v("Hiển thị điểm danh ăn bữa phụ sáng không?")]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.secondMorningEat,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "secondMorningEat",
                            $$v
                          )
                        },
                        expression:
                          "schoolConfigInSysResponse.secondMorningEat",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("24")]),
              _c("td", [_vm._v("Hiển thị điểm danh ăn bữa trưa không?")]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.lunchEat,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "lunchEat",
                            $$v
                          )
                        },
                        expression: "schoolConfigInSysResponse.lunchEat",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("25")]),
              _c("td", [_vm._v("Hiển thị điểm danh ăn bữa chiều không?")]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.afternoonEat,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "afternoonEat",
                            $$v
                          )
                        },
                        expression: "schoolConfigInSysResponse.afternoonEat",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("26")]),
              _c("td", [_vm._v("Hiển thị điểm danh ăn bữa phụ chiều không?")]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.secondAfternoonEat,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "secondAfternoonEat",
                            $$v
                          )
                        },
                        expression:
                          "schoolConfigInSysResponse.secondAfternoonEat",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("27")]),
              _c("td", [_vm._v("Hiển thị điểm danh ăn bữa tối không?")]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.eveningEat,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "eveningEat",
                            $$v
                          )
                        },
                        expression: "schoolConfigInSysResponse.eveningEat",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("28")]),
              _c("td", [
                _vm._v("Có cần duyệt các thông báo gửi đi hay không?"),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.appSendApproved,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "appSendApproved",
                            $$v
                          )
                        },
                        expression: "schoolConfigInSysResponse.appSendApproved",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("29")]),
              _c("td", [
                _vm._v("Có cần duyệt nội dung thời khóa biểu hay không?"),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.approvedSchedule,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "approvedSchedule",
                            $$v
                          )
                        },
                        expression:
                          "schoolConfigInSysResponse.approvedSchedule",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("30")]),
              _c("td", [_vm._v("Có cần duyệt nội dung thực đơn hay không?")]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.approvedMenu,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "approvedMenu",
                            $$v
                          )
                        },
                        expression: "schoolConfigInSysResponse.approvedMenu",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("31")]),
              _c("td", [
                _vm._v(
                  "\n            Cho phép hiển thị số điện thoại của Phụ huynh với App Giáo viên?\n          "
                ),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.parentPhone,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "parentPhone",
                            $$v
                          )
                        },
                        expression: "schoolConfigInSysResponse.parentPhone",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("32")]),
              _c("td", [
                _vm._v(
                  "\n            Cho phép Giáo viên xem thông tin chi tiết của Phụ huynh hay\n            không?\n          "
                ),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.parentInfo,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "parentInfo",
                            $$v
                          )
                        },
                        expression: "schoolConfigInSysResponse.parentInfo",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("33")]),
              _c("td", [
                _vm._v(
                  "\n            Cho phép hiển thị số điện thoại của Giáo viên với App Phụ huynh?\n          "
                ),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.employeePhone,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "employeePhone",
                            $$v
                          )
                        },
                        expression: "schoolConfigInSysResponse.employeePhone",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("34")]),
              _c("td", [
                _vm._v(
                  "\n            Cho phép Phụ huynh xem thông tin chi tiết của Giáo viên không?\n          "
                ),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.employeeInfo,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "employeeInfo",
                            $$v
                          )
                        },
                        expression: "schoolConfigInSysResponse.employeeInfo",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("35")]),
              _c("td", [
                _vm._v(
                  "\n            Cho phép Phụ huynh xem lại lịch sử học của con khi đã chuyển\n            trường hay không?\n          "
                ),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.historyViewParent,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "historyViewParent",
                            $$v
                          )
                        },
                        expression:
                          "schoolConfigInSysResponse.historyViewParent",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("36")]),
              _c("td", [
                _vm._v(
                  "\n            Cho phép Nhân viên xem lại lịch sử làm việc khi đã nghỉ làm ở\n            trường hày không?\n          "
                ),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.historyViewTeacher,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "historyViewTeacher",
                            $$v
                          )
                        },
                        expression:
                          "schoolConfigInSysResponse.historyViewTeacher",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("37")]),
              _c("td", [
                _vm._v(
                  "\n            Giáo viên có được xem chi tiết các khoản thu của học sinh lớp\n            mình hay không?\n          "
                ),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.feesInfo,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "feesInfo",
                            $$v
                          )
                        },
                        expression: "schoolConfigInSysResponse.feesInfo",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("38")]),
              _c("td", [
                _vm._v(
                  "\n            Giáo viên có được xem số tiền học phí của học sinh hay không?\n          "
                ),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.feesNumber,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "feesNumber",
                            $$v
                          )
                        },
                        expression: "schoolConfigInSysResponse.feesNumber",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("39")]),
              _c("td", [
                _vm._v(
                  "Thiết lập giờ đến quy định của Nhà trường với học sinh?"
                ),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c("el-time-picker", {
                    staticClass: "input-data",
                    attrs: {
                      size: "mini",
                      clearable: false,
                      type: "time",
                      "value-format": "HH:mm",
                      format: "HH:mm",
                      "picker-options": {
                        selectableRange: "06:00:00 - 21:00:00",
                      },
                      placeholder: "Chọn",
                    },
                    model: {
                      value: _vm.schoolConfigInSysResponse.timeArriveKid,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.schoolConfigInSysResponse,
                          "timeArriveKid",
                          $$v
                        )
                      },
                      expression: "schoolConfigInSysResponse.timeArriveKid",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("40")]),
              _c("td", [
                _vm._v(
                  "Thiết lập giờ về quy định của Nhà trường với học sinh?"
                ),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c("el-time-picker", {
                    staticClass: "input-data",
                    attrs: {
                      size: "mini",
                      clearable: false,
                      type: "time",
                      "value-format": "HH:mm",
                      format: "HH:mm",
                      "picker-options": {
                        selectableRange: "06:00:00 - 21:00:00",
                      },
                      placeholder: "Chọn",
                    },
                    model: {
                      value: _vm.schoolConfigInSysResponse.timePickupKid,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.schoolConfigInSysResponse,
                          "timePickupKid",
                          $$v
                        )
                      },
                      expression: "schoolConfigInSysResponse.timePickupKid",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("41")]),
              _c("td", [_vm._v("Hiển thị thêm mẫu nhận xét của hệ thống?")]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.showEvaluateSys,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "showEvaluateSys",
                            $$v
                          )
                        },
                        expression: "schoolConfigInSysResponse.showEvaluateSys",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("42")]),
              _c("td", [
                _vm._v("Hiển thị thêm mẫu báo đến, báo về của hệ thống?"),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value:
                          _vm.schoolConfigInSysResponse.showAttentendanceSys,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "showAttentendanceSys",
                            $$v
                          )
                        },
                        expression:
                          "schoolConfigInSysResponse.showAttentendanceSys",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("43")]),
              _c("td", [_vm._v("Hiển thị thêm mẫu lời chúc của hệ thống?")]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.showWishSys,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "showWishSys",
                            $$v
                          )
                        },
                        expression: "schoolConfigInSysResponse.showWishSys",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("44")]),
              _c("td", [
                _vm._v(
                  "Thiết lập giờ đi làm buổi sáng của Nhân viên Nhà trường?"
                ),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c("el-time-picker", {
                    staticClass: "input-data",
                    attrs: {
                      size: "mini",
                      clearable: false,
                      type: "time",
                      "value-format": "HH:mm",
                      format: "HH:mm",
                      "picker-options": {
                        selectableRange: "06:00:00 - 21:00:00",
                      },
                      placeholder: "Chọn",
                    },
                    model: {
                      value: _vm.schoolConfigInSysResponse.timeArriveEmployee,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.schoolConfigInSysResponse,
                          "timeArriveEmployee",
                          $$v
                        )
                      },
                      expression:
                        "schoolConfigInSysResponse.timeArriveEmployee",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("45")]),
              _c("td", [_vm._v("Thiết lập giờ về của Nhân viên Nhà trường?")]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c("el-time-picker", {
                    staticClass: "input-data",
                    attrs: {
                      size: "mini",
                      clearable: false,
                      type: "time",
                      "value-format": "HH:mm",
                      format: "HH:mm",
                      "picker-options": {
                        selectableRange: "06:00:00 - 21:00:00",
                      },
                      placeholder: "Chọn",
                    },
                    model: {
                      value: _vm.schoolConfigInSysResponse.timeLeaveEmployee,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.schoolConfigInSysResponse,
                          "timeLeaveEmployee",
                          $$v
                        )
                      },
                      expression: "schoolConfigInSysResponse.timeLeaveEmployee",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("46")]),
              _c("td", [_vm._v("Có tự động duyệt các phiếu thu không?")]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.receiptNote,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "receiptNote",
                            $$v
                          )
                        },
                        expression: "schoolConfigInSysResponse.receiptNote",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("47")]),
              _c("td", [_vm._v("Có tự động duyệt các phiếu chi không?")]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.paymentNote,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "paymentNote",
                            $$v
                          )
                        },
                        expression: "schoolConfigInSysResponse.paymentNote",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("48")]),
              _c("td", [
                _vm._v(
                  "\n            Có sinh tự động hóa đơn công lương của Nhân viên cho tháng tiếp\n            theo không (sinh tự động vào ngày đầu tháng)?\n          "
                ),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value:
                          _vm.schoolConfigInSysResponse.autoBillSalaryEmployee,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "autoBillSalaryEmployee",
                            $$v
                          )
                        },
                        expression:
                          "schoolConfigInSysResponse.autoBillSalaryEmployee",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("49")]),
              _c("td", [
                _vm._v(
                  "\n            Có sinh tự động các khoản công lương cho Nhân viên theo các\n            khoản mặc định?\n          "
                ),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value:
                          _vm.schoolConfigInSysResponse.autoSignSalaryEmployee,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "autoSignSalaryEmployee",
                            $$v
                          )
                        },
                        expression:
                          "schoolConfigInSysResponse.autoSignSalaryEmployee",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("50")]),
              _c("td", [
                _vm._v(
                  "\n            Thiết lập ngày của tháng sẽ sinh tự động các khoản công lương\n            cho Nhân sự?\n          "
                ),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c("el-input-number", {
                    attrs: {
                      disabled:
                        !_vm.schoolConfigInSysResponse.autoSignSalaryEmployee,
                      size: "mini",
                      min: 1,
                      max: 31,
                    },
                    model: {
                      value:
                        _vm.schoolConfigInSysResponse.autoNextmonthSalaryDate,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.schoolConfigInSysResponse,
                          "autoNextmonthSalaryDate",
                          $$v
                        )
                      },
                      expression:
                        "schoolConfigInSysResponse.autoNextmonthSalaryDate",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("51")]),
              _c("td", [
                _vm._v("Có tự động duyệt các khoản lương hàng tháng không?"),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.autoApprovedSalary,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "autoApprovedSalary",
                            $$v
                          )
                        },
                        expression:
                          "schoolConfigInSysResponse.autoApprovedSalary",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("52")]),
              _c("td", [
                _vm._v(
                  "\n            Có tự động khóa các khoản lương khi đã thanh toán đủ không?\n          "
                ),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value:
                          _vm.schoolConfigInSysResponse.autoLockSalarySuccess,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "autoLockSalarySuccess",
                            $$v
                          )
                        },
                        expression:
                          "schoolConfigInSysResponse.autoLockSalarySuccess",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("53")]),
              _c("td", [
                _vm._v(
                  "\n            Có tự động hiển thị hóa đơn lương của cả tháng khi hóa đơn đã\n            được sinh ra không?\n          "
                ),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value:
                          _vm.schoolConfigInSysResponse.autoShowBillOfMonth,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "autoShowBillOfMonth",
                            $$v
                          )
                        },
                        expression:
                          "schoolConfigInSysResponse.autoShowBillOfMonth",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("54")]),
              _c("td", [
                _vm._v(
                  "\n            Có sinh tự động hóa đơn học phí của học sinh cho tháng tiếp theo\n            không (sinh tự động vào ngày đầu tháng)?\n          "
                ),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value:
                          _vm.schoolConfigInSysResponse.autoFeesApprovedKids,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "autoFeesApprovedKids",
                            $$v
                          )
                        },
                        expression:
                          "schoolConfigInSysResponse.autoFeesApprovedKids",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("55")]),
              _c("td", [
                _vm._v(
                  "\n            Có sinh tự động các khoản thu với học sinh theo các khoản thu đã\n            đăng ký?\n          "
                ),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value:
                          _vm.schoolConfigInSysResponse.autoGenerateFeesKids,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "autoGenerateFeesKids",
                            $$v
                          )
                        },
                        expression:
                          "schoolConfigInSysResponse.autoGenerateFeesKids",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("56")]),
              _c("td", [
                _vm._v(
                  "\n            Thiết lập ngày của tháng sẽ sinh tự động các khoản thu cho học\n            sinh?\n          "
                ),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c("el-input-number", {
                    attrs: {
                      disabled:
                        !_vm.schoolConfigInSysResponse.autoGenerateFeesKids,
                      size: "mini",
                      min: 1,
                      max: 31,
                    },
                    model: {
                      value: _vm.schoolConfigInSysResponse.autoNexMonthFeesDate,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.schoolConfigInSysResponse,
                          "autoNexMonthFeesDate",
                          $$v
                        )
                      },
                      expression:
                        "schoolConfigInSysResponse.autoNexMonthFeesDate",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("57")]),
              _c("td", [
                _vm._v(
                  "\n            Có tự động duyệt các khoản thu của học sinh hàng tháng không?\n          "
                ),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value:
                          _vm.schoolConfigInSysResponse
                            .autoApprovedFeesMonthKids,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "autoApprovedFeesMonthKids",
                            $$v
                          )
                        },
                        expression:
                          "schoolConfigInSysResponse.autoApprovedFeesMonthKids",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("58")]),
              _c("td", [
                _vm._v(
                  "\n            Có tự động khóa các khoản thu khi Phụ huynh đã thanh toán đủ\n            không?\n          "
                ),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value:
                          _vm.schoolConfigInSysResponse.autoLockFeesSuccessKids,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "autoLockFeesSuccessKids",
                            $$v
                          )
                        },
                        expression:
                          "schoolConfigInSysResponse.autoLockFeesSuccessKids",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("59")]),
              _c("td", [
                _vm._v(
                  "\n            Có tự động hiển thị hóa đơn học phí của cả tháng khi hóa đơn đã\n            được sinh ra không?\n          "
                ),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.autoShowFeesMonth,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "autoShowFeesMonth",
                            $$v
                          )
                        },
                        expression:
                          "schoolConfigInSysResponse.autoShowFeesMonth",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("60")]),
              _c("td", [
                _vm._v(
                  "\n            Có hiển thị thông tin các khoản thu dự kiến cho Phụ huynh học\n            sinh không?\n          "
                ),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value:
                          _vm.schoolConfigInSysResponse.autoShowFeesFutureKids,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "autoShowFeesFutureKids",
                            $$v
                          )
                        },
                        expression:
                          "schoolConfigInSysResponse.autoShowFeesFutureKids",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("61")]),
              _c("td", [
                _vm._v("Có cho phép Phụ huynh truy cập ví hay không?"),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.showWalletParent,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "showWalletParent",
                            $$v
                          )
                        },
                        expression:
                          "schoolConfigInSysResponse.showWalletParent",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("62")]),
              _c("td", [
                _vm._v(
                  "\n            Có hiển thị thông tin khoản thu dự kiến của học sinh cho Giáo\n            viên không?\n          "
                ),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value:
                          _vm.schoolConfigInSysResponse
                            .autoShowFeesFutureKidsForTeacher,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "autoShowFeesFutureKidsForTeacher",
                            $$v
                          )
                        },
                        expression:
                          "schoolConfigInSysResponse.autoShowFeesFutureKidsForTeacher",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("63")]),
              _c("td", [
                _vm._v("Hiển thị nút xóa nhân sự, học sinh hay không?"),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.deleteStatus,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "deleteStatus",
                            $$v
                          )
                        },
                        expression: "schoolConfigInSysResponse.deleteStatus",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("64")]),
              _c("td", [_vm._v("Mã xử lý trùng tài khoản")]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c("el-input", {
                    attrs: { size: "mini", maxlength: 6 },
                    model: {
                      value: _vm.schoolConfigInSysResponse.verifyCode,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.schoolConfigInSysResponse,
                          "verifyCode",
                          $$v
                        )
                      },
                      expression: "schoolConfigInSysResponse.verifyCode",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("65")]),
              _c("td", [
                _vm._v(
                  "\n            Có cho phép ra trường, nghỉ học khi chưa hoàn thành học phí\n            không?\n          "
                ),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value:
                          _vm.schoolConfigInSysResponse.checkCompleteFeesStatus,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "checkCompleteFeesStatus",
                            $$v
                          )
                        },
                        expression:
                          "schoolConfigInSysResponse.checkCompleteFeesStatus",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("66")]),
              _c("td", [
                _vm._v("Có gửi thông báo khi Nhà trường rút tiền từ ví không?"),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value:
                          _vm.schoolConfigInSysResponse.notifyWalletOutStatus,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "notifyWalletOutStatus",
                            $$v
                          )
                        },
                        expression:
                          "schoolConfigInSysResponse.notifyWalletOutStatus",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("67")]),
              _c("td", [
                _vm._v("Hiện OneCam Plus trong danh sách Camera Nhà trường?"),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.showOnecamPlus,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "showOnecamPlus",
                            $$v
                          )
                        },
                        expression: "schoolConfigInSysResponse.showOnecamPlus",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("68")]),
              _c("td", [_vm._v("Số lượng ảnh tối đa khi đăng Album")]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c("el-input", {
                    attrs: { size: "mini" },
                    model: {
                      value: _vm.schoolConfigInSysResponse.albumMaxNumber,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.schoolConfigInSysResponse,
                          "albumMaxNumber",
                          $$v
                        )
                      },
                      expression: "schoolConfigInSysResponse.albumMaxNumber",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("70")]),
              _c("td", [
                _vm._v("Áp dụng tự động điền điểm danh ăn sáng theo đi học?"),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.breakfastAuto,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "breakfastAuto",
                            $$v
                          )
                        },
                        expression: "schoolConfigInSysResponse.breakfastAuto",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("71")]),
              _c("td", [
                _vm._v(
                  "Áp dụng tự động điền điểm danh ăn phụ sáng theo đi học?"
                ),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value:
                          _vm.schoolConfigInSysResponse.secondBreakfastAuto,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "secondBreakfastAuto",
                            $$v
                          )
                        },
                        expression:
                          "schoolConfigInSysResponse.secondBreakfastAuto",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("72")]),
              _c("td", [
                _vm._v("Áp dụng tự động điền điểm danh ăn trưa theo đi học?"),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.lunchAuto,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "lunchAuto",
                            $$v
                          )
                        },
                        expression: "schoolConfigInSysResponse.lunchAuto",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("73")]),
              _c("td", [
                _vm._v("Áp dụng tự động điền điểm danh ăn chiều theo đi học?"),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.afternoonAuto,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "afternoonAuto",
                            $$v
                          )
                        },
                        expression: "schoolConfigInSysResponse.afternoonAuto",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("74")]),
              _c("td", [
                _vm._v(
                  "Áp dụng tự động điền điểm danh ăn phụ chiều theo đi học?"
                ),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value:
                          _vm.schoolConfigInSysResponse.secondAfternoonAuto,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "secondAfternoonAuto",
                            $$v
                          )
                        },
                        expression:
                          "schoolConfigInSysResponse.secondAfternoonAuto",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("75")]),
              _c("td", [
                _vm._v("Áp dụng tự động điền điểm danh ăn tối theo đi học?"),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.dinnerAuto,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "dinnerAuto",
                            $$v
                          )
                        },
                        expression: "schoolConfigInSysResponse.dinnerAuto",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("76")]),
              _c("td", [
                _vm._v(
                  "Có tự động tạo điểm danh khi học sinh xin nghỉ học quá thời hạn quy định hay không?"
                ),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value:
                          _vm.schoolConfigInSysResponse.attendanceCreateLetter,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "attendanceCreateLetter",
                            $$v
                          )
                        },
                        expression:
                          "schoolConfigInSysResponse.attendanceCreateLetter",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("77")]),
              _c("td", [
                _vm._v(
                  "Chọn trạng thái điểm danh mặc định khi xin nghỉ quá thời hạn?"
                ),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schoolConfigInSysResponse.absentYesNoLetter,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "absentYesNoLetter",
                            $$v
                          )
                        },
                        expression:
                          "schoolConfigInSysResponse.absentYesNoLetter",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có phép"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không phép"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", { staticClass: "disable-row" }, [
              _c("td", { staticClass: "table-index" }, [_vm._v("78")]),
              _c("td", [_vm._v("Thời gian gửi lời chúc mừng mặc định?")]),
              _c("td", { staticClass: "table-action" }, [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm._f("formatTime")(
                        _vm.sysConfigResponse.timeSendCelebrate
                      )
                    )
                  ),
                ]),
              ]),
            ]),
            _c("tr", { staticClass: "disable-row" }, [
              _c("td", { staticClass: "table-index" }, [_vm._v("79")]),
              _c("td", [_vm._v("Chất lượng ảnh theo %?")]),
              _c("td", { staticClass: "table-action" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.sysConfigResponse.qualityPicture)),
                ]),
              ]),
            ]),
            _c("tr", { staticClass: "disable-row" }, [
              _c("td", { staticClass: "table-index" }, [_vm._v("80")]),
              _c("td", [_vm._v("Chất lượng ảnh theo kích thước?")]),
              _c("td", { staticClass: "table-action" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.sysConfigResponse.widthPicture)),
                ]),
              ]),
            ]),
            _c("tr", { staticClass: "disable-row" }, [
              _c("td", { staticClass: "table-index" }, [_vm._v("81")]),
              _c("td", [_vm._v("Chèn tiêu đề vào nội dung tin nhắn?")]),
              _c("td", { staticClass: "table-action" }, [
                _vm.sysConfigResponse.showTitleSms
                  ? _c("span", [_vm._v("Có")])
                  : _c("span", [_vm._v("Không")]),
              ]),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index disable-row" }, [
                _vm._v("82"),
              ]),
              _c("td", { staticClass: "disable-row" }, [
                _vm._v(
                  "\n            Nội dung tiêu đề mặc định trong tin nhắn SMS?\n          "
                ),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "top-start",
                        title: "Nội dung tiêu đề",
                        width: "300",
                        trigger: "hover",
                        content: _vm.sysConfigResponse.titleContentSms,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { slot: "reference", type: "text" },
                          slot: "reference",
                        },
                        [_vm._v("Xem")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("83")]),
              _c("td", [_vm._v("Thiết lập thời gian đón muộn")]),
              _c("td", { staticClass: "table-action" }, [
                _c("table", { staticClass: "center-table" }, [
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.schoolConfigInSysResponse.statusBlock,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.schoolConfigInSysResponse,
                                  "statusBlock",
                                  $$v
                                )
                              },
                              expression:
                                "schoolConfigInSysResponse.statusBlock",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: true } }, [
                              _vm._v("Block"),
                            ]),
                            _c("el-radio", { attrs: { label: false } }, [
                              _vm._v("Ngày"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c(
                          "span",
                          { staticStyle: { "margin-bottom": "10px" } },
                          [_vm._v("Nhập số phút")]
                        ),
                        _c(
                          "el-input-number",
                          {
                            staticStyle: { width: "200px" },
                            attrs: {
                              min: 0,
                              step: 1,
                              placeholder: "Nhập số phút",
                            },
                            model: {
                              value:
                                _vm.schoolConfigInSysResponse.blockLeaveKid,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.schoolConfigInSysResponse,
                                  "blockLeaveKid",
                                  $$v
                                )
                              },
                              expression:
                                "schoolConfigInSysResponse.blockLeaveKid",
                            },
                          },
                          [
                            _vm._v(
                              "\n                    >\n                  "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.schoolConfigInSysResponse.statusUpDown,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.schoolConfigInSysResponse,
                                  "statusUpDown",
                                  $$v
                                )
                              },
                              expression:
                                "schoolConfigInSysResponse.statusUpDown",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: true } }, [
                              _vm._v("Làm tròn lên"),
                            ]),
                            _c("el-radio", { attrs: { label: false } }, [
                              _vm._v("Làm tròn xuống"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("84")]),
              _c("td", [
                _vm._v("Cấu hình thứ tự hiển thị tin tức màn Home trên App?"),
              ]),
              _c("td", [
                _c("div", { staticClass: "sttShowTabHomeTable" }, [
                  _c("table", [
                    _vm._m(1),
                    _c("tr", [
                      _c(
                        "td",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c("el-checkbox", {
                            attrs: { disabled: true },
                            model: {
                              value:
                                _vm.schoolConfigInSysResponse.activeShowNews,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.schoolConfigInSysResponse,
                                  "activeShowNews",
                                  $$v
                                )
                              },
                              expression:
                                "schoolConfigInSysResponse.activeShowNews",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c("el-checkbox", {
                            on: { change: _vm.changeShowFacebook },
                            model: {
                              value:
                                _vm.schoolConfigInSysResponse
                                  .activeShowFacebook,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.schoolConfigInSysResponse,
                                  "activeShowFacebook",
                                  $$v
                                )
                              },
                              expression:
                                "schoolConfigInSysResponse.activeShowFacebook",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c("el-checkbox", {
                            on: { change: _vm.changeShowVideo },
                            model: {
                              value:
                                _vm.schoolConfigInSysResponse.activeShowVideo,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.schoolConfigInSysResponse,
                                  "activeShowVideo",
                                  $$v
                                )
                              },
                              expression:
                                "schoolConfigInSysResponse.activeShowVideo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c("el-checkbox", {
                            on: { change: _vm.changeShowWebsiteSchool },
                            model: {
                              value:
                                _vm.schoolConfigInSysResponse
                                  .activeShowWebsiteSchool,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.schoolConfigInSysResponse,
                                  "activeShowWebsiteSchool",
                                  $$v
                                )
                              },
                              expression:
                                "schoolConfigInSysResponse.activeShowWebsiteSchool",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c("el-checkbox", {
                            on: { change: _vm.changeShowTiktok },
                            model: {
                              value:
                                _vm.schoolConfigInSysResponse.activeShowTiktok,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.schoolConfigInSysResponse,
                                  "activeShowTiktok",
                                  $$v
                                )
                              },
                              expression:
                                "schoolConfigInSysResponse.activeShowTiktok",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c("el-checkbox", {
                            on: { change: _vm.changeShowOneKidsPage },
                            model: {
                              value:
                                _vm.schoolConfigInSysResponse
                                  .activeShowOneKidsPage,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.schoolConfigInSysResponse,
                                  "activeShowOneKidsPage",
                                  $$v
                                )
                              },
                              expression:
                                "schoolConfigInSysResponse.activeShowOneKidsPage",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("tr", [
                      _c(
                        "td",
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "input-common",
                              attrs: {
                                placeholder: "Chọn thứ tự hiển thị tin tức",
                              },
                              on: { change: _vm.changeNumberShowNews },
                              model: {
                                value:
                                  _vm.schoolConfigInSysResponse.sttShowNews,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.schoolConfigInSysResponse,
                                    "sttShowNews",
                                    $$v
                                  )
                                },
                                expression:
                                  "schoolConfigInSysResponse.sttShowNews",
                              },
                            },
                            _vm._l(_vm.numberSelect, function (item) {
                              return _c("el-option", {
                                key: item,
                                attrs: {
                                  disabled:
                                    item ===
                                    _vm.schoolConfigInSysResponse.sttShowNews,
                                  value: item,
                                  label: `${item}`,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "input-common",
                              attrs: {
                                placeholder: "Chọn thứ tự hiển thị Facebook",
                                disabled:
                                  !_vm.schoolConfigInSysResponse
                                    .activeShowFacebook,
                              },
                              on: { change: _vm.changeNumberShowFacebook },
                              model: {
                                value:
                                  _vm.schoolConfigInSysResponse.sttShowFacebook,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.schoolConfigInSysResponse,
                                    "sttShowFacebook",
                                    $$v
                                  )
                                },
                                expression:
                                  "schoolConfigInSysResponse.sttShowFacebook",
                              },
                            },
                            _vm._l(_vm.numberSelect, function (item) {
                              return _c("el-option", {
                                key: item,
                                attrs: {
                                  disabled:
                                    item ===
                                    _vm.schoolConfigInSysResponse
                                      .sttShowFacebook,
                                  value: item,
                                  label: `${item}`,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "input-common",
                              attrs: {
                                placeholder: "Chọn thứ tự hiển thị Video",
                                disabled:
                                  !_vm.schoolConfigInSysResponse
                                    .activeShowVideo,
                              },
                              on: { change: _vm.changeNumberShowVideo },
                              model: {
                                value:
                                  _vm.schoolConfigInSysResponse.sttShowVideo,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.schoolConfigInSysResponse,
                                    "sttShowVideo",
                                    $$v
                                  )
                                },
                                expression:
                                  "schoolConfigInSysResponse.sttShowVideo",
                              },
                            },
                            _vm._l(_vm.numberSelect, function (item) {
                              return _c("el-option", {
                                key: item,
                                attrs: {
                                  disabled:
                                    item ===
                                    _vm.schoolConfigInSysResponse.sttShowVideo,
                                  value: item,
                                  label: `${item}`,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "input-common",
                              attrs: {
                                placeholder:
                                  "Chọn thứ tự hiển thị trang web của trường",
                                disabled:
                                  !_vm.schoolConfigInSysResponse
                                    .activeShowWebsiteSchool,
                              },
                              on: { change: _vm.changeNumberShowWebsiteSchool },
                              model: {
                                value:
                                  _vm.schoolConfigInSysResponse
                                    .sttShowWebsiteSchool,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.schoolConfigInSysResponse,
                                    "sttShowWebsiteSchool",
                                    $$v
                                  )
                                },
                                expression:
                                  "schoolConfigInSysResponse.sttShowWebsiteSchool",
                              },
                            },
                            _vm._l(_vm.numberSelect, function (item) {
                              return _c("el-option", {
                                key: item,
                                attrs: {
                                  disabled:
                                    item ===
                                    _vm.schoolConfigInSysResponse
                                      .sttShowWebsiteSchool,
                                  value: item,
                                  label: `${item}`,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "input-common",
                              attrs: {
                                placeholder: "Chọn thứ tự hiển thị Tiktok",
                                disabled:
                                  !_vm.schoolConfigInSysResponse
                                    .activeShowTiktok,
                              },
                              on: { change: _vm.changeNumberShowTiktok },
                              model: {
                                value:
                                  _vm.schoolConfigInSysResponse.sttShowTiktok,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.schoolConfigInSysResponse,
                                    "sttShowTiktok",
                                    $$v
                                  )
                                },
                                expression:
                                  "schoolConfigInSysResponse.sttShowTiktok",
                              },
                            },
                            _vm._l(_vm.numberSelect, function (item) {
                              return _c("el-option", {
                                key: item,
                                attrs: {
                                  disabled:
                                    item ===
                                    _vm.schoolConfigInSysResponse.sttShowTiktok,
                                  value: item,
                                  label: `${item}`,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "input-common",
                              attrs: {
                                placeholder:
                                  "Chọn thứ tự hiển thị trang web của Onekids",
                                disabled:
                                  !_vm.schoolConfigInSysResponse
                                    .activeShowOneKidsPage,
                              },
                              on: { change: _vm.changeNumberShowOneKidsPage },
                              model: {
                                value:
                                  _vm.schoolConfigInSysResponse
                                    .sttShowOneKidsPage,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.schoolConfigInSysResponse,
                                    "sttShowOneKidsPage",
                                    $$v
                                  )
                                },
                                expression:
                                  "schoolConfigInSysResponse.sttShowOneKidsPage",
                              },
                            },
                            _vm._l(_vm.numberSelect, function (item) {
                              return _c("el-option", {
                                key: item,
                                attrs: {
                                  disabled:
                                    item ===
                                    _vm.schoolConfigInSysResponse
                                      .sttShowOneKidsPage,
                                  value: item,
                                  label: `${item}`,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c("tr", [
              _c("td", { staticClass: "table-index" }, [_vm._v("85")]),
              _c("td", [
                _vm._v(
                  "Có tự động xóa dữ liệu điểm danh, nhận xét trùng hay không?"
                ),
              ]),
              _c(
                "td",
                { staticClass: "table-action" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value:
                          _vm.schoolConfigInSysResponse.attendanceDuplicate,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.schoolConfigInSysResponse,
                            "attendanceDuplicate",
                            $$v
                          )
                        },
                        expression:
                          "schoolConfigInSysResponse.attendanceDuplicate",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Có"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Không"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              staticClass: "button-click",
              staticStyle: { "border-radius": "0" },
              attrs: {
                loading: _vm.loaddingButton,
                size: "medium",
                type: "success",
              },
              on: {
                click: function ($event) {
                  return _vm.submitConfigCommon()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-check" }),
              _c("span", [_vm._v("Lưu")]),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("td", { staticClass: "table-index" }, [_vm._v("STT")]),
        _c("td", { staticStyle: { "text-align": "center" } }, [
          _vm._v("Nội dung"),
        ]),
        _c("td", { staticClass: "table-action" }, [_vm._v("Thiết lập")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticStyle: { "text-align": "center" } }, [
        _vm._v("Tin tức"),
      ]),
      _c("td", { staticStyle: { "text-align": "center" } }, [
        _vm._v("Facebook"),
      ]),
      _c("td", { staticStyle: { "text-align": "center" } }, [_vm._v("Video")]),
      _c("td", { staticStyle: { "text-align": "center" } }, [
        _vm._v("Website trường"),
      ]),
      _c("td", { staticStyle: { "text-align": "center" } }, [_vm._v("Tiktok")]),
      _c("td", { staticStyle: { "text-align": "center" } }, [
        _vm._v("Onekids Page"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }